<template>
  <v-app>
    <Layouts>
      <ErrorBoundary :error-message="error.message">
        <router-view
          v-bind="appProps"
        />
      </ErrorBoundary>
      <Snackbar
        :text="snackbars.text"
        :type="snackbars.type"
        :display="snackbars.display"
        :permanent="snackbars.permanent"
        :handle-close="hideSnackbars"
      />
    </Layouts>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Layouts from "@/components/layouts";
import ErrorBoundary from "@/components/ErrorBoundary";
import Snackbar from "@/components/Snackbar";

export default {
  name: "App",
  components: {
    ErrorBoundary,
    Layouts,
    Snackbar,
  },
  props: {
    appProps: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    }
  },
  data(){
    return {
      errorMessage: "",
    };
  },
  computed: {
    ...mapState(["error", "snackbars"])
  },
  beforeMount() {
    // sessionsレイアウトを使用している画面は、
    // messagesが1つしか返ってこない画面のため1つにのみ対応
    if (this.messages.texts && this.messages.texts.length > 0) {
      this.showSnackbars({
        text: this.messages.texts[0],
        type: this.messages.type,
      });
    }
  },
  errorCaptured(err) {
    // エラーハンドリング
    // https://jp.vuejs.org/v2/api/index.html#errorCaptured
    if (err.isAxiosError) {
      this.setError(err.message);
    }
    if (!(err instanceof TypeError)) {
      this.setError(err.toString());
    }
    this.$sentry.captureException(err);
  },
  methods: {
    ...mapActions( "error", [ "setError" ] ),
    ...mapActions( "snackbars", [ "showSnackbars", "hideSnackbars" ] ),
  }
};
</script>