<template>
  <v-main class="grey lighten-4 mb-6">
    <v-sheet
      class="d-flex flex-column justify-space-between"
      style="min-height: 96vh"
      color="transparent"
    >
      <slot />
      <LayoutFooter />
    </v-sheet>
  </v-main>
</template>

<script>
import LayoutFooter from "@/shared/components/layout/layoutFooter";

export default {
  components: {
    LayoutFooter,
  }

};
</script>